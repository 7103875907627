import React, { useState } from 'react';  // Add useState here

import { Divider } from '@material-ui/core';
// import StarRating from '../star-rating/starrating.component';
import './offerpost.style.css';
import { LazyLoadImage } from 'react-lazy-load-image-component'; // Import LazyLoadImage
import 'react-lazy-load-image-component/src/effects/blur.css'; // Optional: for blur effect

const OfferCard = ({ index, ...i }) => {
// READMORE - add1
  const [isExpanded, setIsExpanded] = useState(false); // State to track if description is expanded
  const charLimit = 500; // Character limit for truncation

  const hideButton = !i.btnTitle;

  // Function to handle image source
  const getImageSource = () => {
    if (i.image.startsWith('http')) {
      // If img starts with 'http', assume it's a remote image
      return i.image;
    } else if (i.image.endsWith('.gif')) {
      // If img ends with '.gif', assume it's a local image in the public folder
      return `${process.env.PUBLIC_URL}${i.image}`;
    } else {
      // Assume it's a regular local image
      return `${process.env.PUBLIC_URL}/images/${i.image}`;
    }
  };

// READMORE - add2
    // Toggle between expanded and truncated description
    const toggleReadMore = () => {
      setIsExpanded(!isExpanded);
    };
  
    // Conditionally truncate description if not expanded
    const displayDescription = !isExpanded && i.description.length > charLimit
      ? `${i.description.substring(0, charLimit)}...`
      : i.description;
  
// READMORE - add3 - Updated the div for gradient wrapper. Added read more and less buttons  
  return (
    <>
      <div className="offer_post">
        <h2>
          <a className='title__link' href={i.url}>
            <span className="unit">{i.count}</span>
            {i.title}
          </a>
        </h2>
        <div className="offer_image">
          {/* Replace <img> with LazyLoadImage */}
          <LazyLoadImage
            alt={i.title}
            src={getImageSource()} // Use the same function to set src
            effect="blur" // Optional: Add blur effect while loading
            // srcSet={`${getImageSource()} 720w, ${getImageSource()} 1280w, ${getImageSource()} 1920w`}
            // sizes="(max-width: 720px) 100vw, (max-width: 1280px) 100vw, 1920px"
          />
        </div>

        {/* Container for gradient effect */}
        <div className={`offer_content_container ${!isExpanded ? 'gradient' : ''}`} 
            style={{ maxHeight: isExpanded ? 'none' : '24em', overflow: isExpanded ? 'visible' : 'hidden' }}>
          <p className="offer_content" dangerouslySetInnerHTML={{ __html: displayDescription }} />
        </div>

        {i.description.length > charLimit && (
          <button onClick={toggleReadMore} className="read-more-btn">
            {isExpanded ? 'READ LESS' : 'READ MORE +'}
          </button>
        )}
        
        <br />
        <a href={i.url} className={hideButton ? 'hideButton' : "offer_link button"} target="_blank" rel="noreferrer">
          {i.btnTitle}
        </a>
      </div>
      <Divider />
    </>
  );
};

export default OfferCard;
